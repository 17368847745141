<template>
  <div>
    <v-skeleton-loader
      v-if="isLoading"
      type="table"
    />

    <div v-else>
      <div class="pa-0 px-1 pb-3 w-full d-flex align-center justify-end">
        <v-dialog
          v-model="isCreating"
          width="500"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              fab
              :ripple="false"
              small
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              新規顧客タグ
            </v-card-title>

            <v-card-text>
              <v-text-field
                v-model="addTarget.name"
                label="名前"
              />

              <v-text-field
                v-model="addTarget.shortcut1"
                label="ショートカット1"
              />

              <v-text-field
                v-model="addTarget.shortcut2"
                label="ショートカット2"
              />
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                :ripple="false"
                @click="isCreating = false; create()"
              >
                作成
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :ripple="false"
                @click="isCreating = false"
              >
                キャンセル
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>

      <v-data-table
        ref="dataTable"
        :key="JSON.stringify(customerTags)"
        v-sortable-data-table
        :headers="headers"
        :items="customerTags"
        :header-props="{ sortByText: 'ソート' }"
        hide-default-footer
        disable-pagination
        no-data-text="データがありません"
        @sorted="updatePositions($event)"
      >
        <template #[`item.attributes.name`]="{item}">
          <yona-edit-dialog
            :display-name="item.attributes.name"
            @open="edit(item)"
            @save="update"
          >
            <template #input>
              <v-text-field
                v-if="isEditing"
                v-model="editTarget.name"
                placeholder="名前"
              />

              <v-text-field
                v-if="isEditing"
                v-model="editTarget.shortcut1"
                placeholder="ショートカット1"
              />

              <v-text-field
                v-if="isEditing"
                v-model="editTarget.shortcut2"
                placeholder="ショートカット2"
              />
            </template>
          </yona-edit-dialog>
        </template>

        <template #[`item.actions`]="{item}">
          <yona-edit-dialog
            title="削除の確認"
            btn-color="error"
            save-text="同意の上で削除"
            @save="destroy(item.id)"
          >
            <template #append-outer-display-name>
              <v-btn
                icon
                :ripple="false"
              >
                <v-icon small>
                  {{ icons.mdiTrashCanOutline }}
                </v-icon>
              </v-btn>
            </template>

            <template #input>
              <span>
                削除を実行してもよろしいですか
              </span>
            </template>
          </yona-edit-dialog>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  getCurrentInstance,
  inject,
} from '@vue/composition-api'
import {
  mdiTrashCanOutline,
  mdiPlus,
} from '@mdi/js'
import { map } from 'lodash'
import CustomerTagApi from '@/api/waiter/CustomerTag'
import YonaEditDialog from '@/views/components/util/YonaEditDialog.vue'
import sortableDataTable from '@/directives/sortableDataTable'

export default {
  components: {
    YonaEditDialog,
  },
  directives: {
    sortableDataTable,
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const customerTags = inject('customerTags', [])

    const isLoading = ref(false)
    const isCreating = ref(false)
    const isEditing = ref(false)
    const editTarget = reactive({
      id: null,
      name: null,
      shortcut1: null,
      shortcut2: null,
    })
    const addTarget = reactive({
      name: '',
      shortcut1: '',
      shortcut2: '',
    })

    const edit = item => {
      isEditing.value = true

      editTarget.id = item.id
      editTarget.name = item.attributes.name
      editTarget.shortcut1 = item.attributes.shortcut1
      editTarget.shortcut2 = item.attributes.shortcut2
    }

    const create = async () => {
      const res = await CustomerTagApi.createCustomerTag({ ...addTarget })

      if (res?.data?.data) {
        customerTags.value.push(res.data.data)
        vm.$toast.success('顧客タグを作成しました')
      }

      isCreating.value = false
      addTarget.name = ''
      addTarget.shortcut1 = ''
      addTarget.shortcut2 = ''
    }

    const update = async () => {
      const {
        id, name, shortcut1, shortcut2,
      } = editTarget
      const res = await CustomerTagApi.updateCustomerTag({
        id, name, shortcut1, shortcut2,
      })

      if (res?.data?.data) {
        const index = customerTags.value.findIndex(o => +o.id === +id)
        customerTags.value.splice(index, 1, res.data.data)
        vm.$toast.success('顧客タグを更新しました')
      }

      isEditing.value = false
      editTarget.id = null
      editTarget.name = null
      editTarget.shortcut1 = null
      editTarget.shortcut2 = null
    }

    const destroy = async id => {
      const res = await CustomerTagApi.deleteCustomerTag(id)

      if (res) {
        if (res.data.status === 'error') {
          vm.$toast.error('削除できません')
          vm.$toast.error(res.data.message.join('\n'))

          return
        }

        const index = customerTags.value.findIndex(o => +o.id === +id)
        customerTags.value.splice(index, 1)
        vm.$toast.success('顧客タグを削除しました')
      }
    }

    const getCustomerTags = async () => {
      isLoading.value = true

      const res = await CustomerTagApi.getCustomerTags()

      if (res?.data) {
        customerTags.value = [...res.data.customerTags.data]
      }

      isLoading.value = false
    }

    const updatePositions = async event => {
      const movedCustomerTag = customerTags.value.splice(event.oldIndex, 1)[0]
      customerTags.value.splice(event.newIndex, 0, movedCustomerTag)
      const idAndPositions = map(customerTags.value, (o, idx) => {
        return { id: o.id, position: idx }
      })

      const res = await CustomerTagApi.updatePositions(idAndPositions)
    }

    getCustomerTags()

    return {
      // data
      isLoading,
      isCreating,
      isEditing,
      customerTags,
      headers: [
        { text: '名前', value: 'attributes.name' },
        {
          text: '',
          value: 'actions',
          align: 'end',
          sortable: false,
        },
      ],
      editTarget,
      addTarget,

      // methods
      edit,
      update,
      destroy,
      create,
      updatePositions,

      icons: {
        mdiTrashCanOutline,
        mdiPlus,
      },
    }
  },
}
</script>
