var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('div',[_c('div',{staticClass:"pa-0 px-1 pb-3 w-full d-flex align-center justify-end"},[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","ripple":false,"small":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1)]}}]),model:{value:(_vm.isCreating),callback:function ($$v) {_vm.isCreating=$$v},expression:"isCreating"}},[_c('v-card',[_c('v-card-title',[_vm._v(" 新規顧客タグ ")]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"名前"},model:{value:(_vm.addTarget.name),callback:function ($$v) {_vm.$set(_vm.addTarget, "name", $$v)},expression:"addTarget.name"}}),_c('v-text-field',{attrs:{"label":"ショートカット1"},model:{value:(_vm.addTarget.shortcut1),callback:function ($$v) {_vm.$set(_vm.addTarget, "shortcut1", $$v)},expression:"addTarget.shortcut1"}}),_c('v-text-field',{attrs:{"label":"ショートカット2"},model:{value:(_vm.addTarget.shortcut2),callback:function ($$v) {_vm.$set(_vm.addTarget, "shortcut2", $$v)},expression:"addTarget.shortcut2"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","ripple":false},on:{"click":function($event){_vm.isCreating = false; _vm.create()}}},[_vm._v(" 作成 ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","ripple":false},on:{"click":function($event){_vm.isCreating = false}}},[_vm._v(" キャンセル ")])],1)],1)],1)],1),_c('v-data-table',{directives:[{name:"sortable-data-table",rawName:"v-sortable-data-table"}],key:JSON.stringify(_vm.customerTags),ref:"dataTable",attrs:{"headers":_vm.headers,"items":_vm.customerTags,"header-props":{ sortByText: 'ソート' },"hide-default-footer":"","disable-pagination":"","no-data-text":"データがありません"},on:{"sorted":function($event){return _vm.updatePositions($event)}},scopedSlots:_vm._u([{key:"item.attributes.name",fn:function(ref){
var item = ref.item;
return [_c('yona-edit-dialog',{attrs:{"display-name":item.attributes.name},on:{"open":function($event){return _vm.edit(item)},"save":_vm.update},scopedSlots:_vm._u([{key:"input",fn:function(){return [(_vm.isEditing)?_c('v-text-field',{attrs:{"placeholder":"名前"},model:{value:(_vm.editTarget.name),callback:function ($$v) {_vm.$set(_vm.editTarget, "name", $$v)},expression:"editTarget.name"}}):_vm._e(),(_vm.isEditing)?_c('v-text-field',{attrs:{"placeholder":"ショートカット1"},model:{value:(_vm.editTarget.shortcut1),callback:function ($$v) {_vm.$set(_vm.editTarget, "shortcut1", $$v)},expression:"editTarget.shortcut1"}}):_vm._e(),(_vm.isEditing)?_c('v-text-field',{attrs:{"placeholder":"ショートカット2"},model:{value:(_vm.editTarget.shortcut2),callback:function ($$v) {_vm.$set(_vm.editTarget, "shortcut2", $$v)},expression:"editTarget.shortcut2"}}):_vm._e()]},proxy:true}],null,true)})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('yona-edit-dialog',{attrs:{"title":"削除の確認","btn-color":"error","save-text":"同意の上で削除"},on:{"save":function($event){return _vm.destroy(item.id)}},scopedSlots:_vm._u([{key:"append-outer-display-name",fn:function(){return [_c('v-btn',{attrs:{"icon":"","ripple":false}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCanOutline)+" ")])],1)]},proxy:true},{key:"input",fn:function(){return [_c('span',[_vm._v(" 削除を実行してもよろしいですか ")])]},proxy:true}],null,true)})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }